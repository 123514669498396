.my-checkbox {
    width: 20px;
    height: 20px;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.3);
    display: inline-block;
    border-radius: 3px;
    display: flex;
    margin: 3px;
    text-align: center;
  }
  
  .my-checkbox.checked {
    background-color: blue;
    color: white;
    text-align: center;
    
  }
  