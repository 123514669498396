.caneca {
    margin-top: 4%;
    width: 40px;
    height: 80px;
    position: relative;
    border: 1.5px solid #666;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.caneca-lid {
    width: 150%;
    height: 10px;
    margin-left: -25%;
    background-color: #fff;
    border: 1px solid #666;
    position: relative;
    z-index: 14;
    margin-top: -1.5px;
    border-radius: 10%;
}

.caneca-handle {
    width: 12px;
    height: 12px;
    border: 1px solid #666;
    border-radius: 50%;
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.caneca-body {
    width: 100%;
    height: 70px; /* El tamaño del cuerpo de la caneca */
    background-color: "transparent";
    position: relative;
    border: 1px solid #666;
    overflow: hidden;
    z-index: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.caneca-fill {
    width: 100%;
    background-color: #D1D5DB;
    position: absolute;
    bottom: 0;
    left: 0;
}
