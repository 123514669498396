.contentMain{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.titleCard{
    font-weight: 900;
}
.Total{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #828181;
}

.Total-span{
    font-weight: 400;
    font-size: 15px;
    margin-left: 2%;
    margin-right: 2%;
    color: #3CAEDF;
}
.span-medidor{
    font-weight: 400;
    font-size: 15px;
    margin-left: 10px;
    color: #3CAEDF;
}