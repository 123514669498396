
/* .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000023;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(22, 22, 22, 0.26);
  }
  
  .modal textarea, .modal input {
    display: block;
    width: 100%;
    margin-top: 10px;
  } */
textarea:focus, input:focus{
    border: none;
    outline: none;
    box-shadow: 0 1px 4px rgba(22, 22, 22, 0.26) inset;
    padding: 10px;
  }