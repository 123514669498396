

.MainBox{
    background-color: whitesmoke;
    padding: 3%;
    width: 70%;
    padding-left: 3%;
    border-radius: 10px;
    position: relative;
    margin-top: 5%;
    max-height: 80vh; /* Establecer un máximo del 80% del alto de la ventana */
    overflow-y: auto; 
}
.MainBox_Gextor{
    margin-top: 5%;
}
.MainBox .boton{
    display: flex;
    margin: auto;
    margin-top: 4%;
}
.botonRuta{
    height: 35px;
    font-size: 20;
}