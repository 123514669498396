
.navDash{
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    padding-right: 5%;
}
.navDash h3{
    margin-top: 10px;
}
.NavIcons{
    display: flex;
    flex-direction: row;
    gap: 10%;
}
.boxDateSelect{
    background-color: #fff;
    position: absolute;
    top: -10px;
    right: -5px;
    text-align: center;
}
.boxDateSelect p:hover{
    background-color: #ccc;
}
.boxDate{
    background-color: #fff;
    padding: 15px;
    position: absolute;
    top: -10px;
    right: -5px;
    height: 150px;
}
.Boxclose{
    position: relative;
}
.close{
    position: absolute;
    top: 0px;
    right: 3px;
    color: tomato;
}
.BoxesDate{
    margin-top: 7%;
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.filt{
    position: absolute;
    margin-top: -3px;
    background-color: transparent;
    border: none;
    outline: none;
    right: 0px;
}