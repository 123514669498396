/* Font Tittle */
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@900&display=swap');
/* Font Text */
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.containerL{
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
}
.imgBackground{
    background-image: url("../../assets/ImgLogin.png");
    background-size: cover;
    background-position-y: 2rem;
    width: 40vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.containerLogin h1{
    font-family: 'Maven Pro', sans-serif;
    width: 100%;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    color: #9CB250;
    position: absolute;
    top: 0px;
}
.LastDiv{
    background-color: #2E4F40;
    width: 64%;
    text-align: center;
    padding-top: 10px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: white;
}
.subtitle{
    font-size: 25px;
    margin-top: -10px;
    font-weight: 500;
    font-family: 'Maven Pro', sans-serif;
}
.containerLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5%;
    align-items: center;
    width: 70vw;
    height: 100vh;
}
.containerLogin form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.recuperar{
    border: none;
    outline: none;
    background-color: transparent;
    color: #9CB250;
    text-decoration: underline;
}
.Logo{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 100;
}
.send{   /* BOTTOM GENERAL*/
    border: none;
    outline: none;
    background-color: #9CB250;
    color: #fff;
    border-radius: 5px;
    width: 50%;
}