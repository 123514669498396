 .navHeader {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: flex-end;
     padding-top: 15px;
     padding-right: 15px;
     padding-left: 25px;
 }

 .chars {
     height: 30px;

 }

 .hilo-Logo {
     height: 40px;
 }

 .img-Hilo {
     width: 200px;
     height: 100px;
     object-fit: "cover";
     border-radius: 10px;
 }

 .childNav {
     display: flex;
     flex-direction: column;
     align-items: flex-end;
     gap: 5px
 }

 .childNav div p {
     margin-bottom: -10px;
 }

 .line {
     width: 100%;
     height: 2px;
     background-color: #2E4F40;
 }

 .span {
     text-align: right;
     margin-top: -7px;
     font-weight: bold;
     padding-right: 15px;
 }


 .table-pdf {
     border-collapse: collapse;
     box-shadow: inset 0 1px 0 #fff;
     font-size: 12px;
     line-height: 24px;
     margin: 30px auto;
     margin-top: 100px;
     text-align: left;
     width: 800px;
     font-size: 20px;
     font-family: sans-serif;
     border-radius: 5px;
     overflow: hidden;
 }


 .th-pdf {
     background: #2E4F408f;
     border-bottom: 1px solid #333;
     box-shadow: 0 1px 0 #2E4F40;
     color: #fff;
     font-weight: bolder;
     padding: 10px 15px;
     position: relative;
     text-shadow: 0 1px 0 #000;
     text-align: center;
     border-bottom: 2px solid white;
 }

 .td-pdf {
     border-bottom: 2px solid white;
     border-right: 1px solid #fff;
     border-left: 1px solid #e8e8e8;
     border-top: 1px solid #fff;
     border-bottom: 1px solid #e8e8e8;
     padding: 10px 15px;
     position: relative;
     transition: all 300ms;
     text-align: center;
     font-weight: bold;
 }

 .footer {
     display: flex;
     flex-direction: row;
     width: 100%;
     justify-content: space-around;
     /* position:absolute; */
     bottom: 0px
 }

 .footer-Child {
     display: flex;
     flex-direction: row;
     gap: 8px
 }

 .footer-Child img {
     height: 20px;
     align-self: center;
     margin-top: -1px
 }

 .total {
     color: #C1A82C;
     background-color: #C1A82C1f;
 }
 .formEmail:focus{
    outline: none;
    border: none;

 }